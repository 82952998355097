import { createContext, useContext } from 'react';
import { User } from '../generated/graphql';

type UserWithoutUserPortfolio = Omit<User, 'UserPortfolio'>;

export const UserContext = createContext<{
  user?: UserWithoutUserPortfolio;
  loading: boolean;
}>({
  loading: true,
});

export const useUser = () => {
  return useContext(UserContext);
};
