import React, { FC } from 'react';
import { Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import Link from 'next/link';
import { NavigationLink } from '../Layout';
import { useUser } from '../../../context/user';
interface Props {
  isOpen: boolean;
  links: NavigationLink[];
  onLogoutClick: () => void;
}

export const MobileMenu: FC<Props> = ({ isOpen, links, onLogoutClick }) => {
  const { user } = useUser();
  const router = useRouter();
  return (
    <>
      <Transition show={isOpen}>
        <nav className="lg:hidden" aria-label="Global">
          <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
            {links.map(link => (
              <Link
                href={link.href}
                passHref
                key={link.href}
                aria-current="page"
                className={clsx(
                  'block rounded-md py-2 px-3 text-base font-medium text-gray-900',
                  {
                    'bg-gray-100 ': router.pathname === link.href,
                    'hover:bg-gray-50': router.pathname !== link.href,
                  },
                )}
              >
                {link.label}
              </Link>
            ))}
          </div>
          {user && (
            <div className="border-t border-gray-200 pt-4 pb-3">
              <div className="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={user.avatar}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {user.name || user.username}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {user.email}
                  </div>
                </div>
              </div>
              <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                <Link
                  href="/u/[userId]"
                  as={`/u/${user.username}`}
                  passHref
                  className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                >
                  Your Profile
                </Link>

                <a
                  href="#"
                  className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                  onClick={() => {
                    onLogoutClick();
                  }}
                >
                  Sign out
                </a>
              </div>
            </div>
          )}
        </nav>
      </Transition>
    </>
  );
};
