import React, { FC } from 'react';

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

export const MobileMenuButton: FC<Props> = ({ isOpen, onClick }) => {
  const icon = isOpen ? (
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 18L18 6M6 6l12 12"
    />
  ) : (
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 6h16M4 12h16M4 18h16"
    />
  );
  return (
    <button
      onClick={() => onClick()}
      type="button"
      className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500"
      aria-expanded="false"
    >
      <span className="sr-only">{isOpen ? 'Close menu' : 'Open menu'}</span>
      <svg
        className="block h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        {icon}
      </svg>
    </button>
  );
};
