import React, { FC, useState, useRef } from 'react';
import Link from 'next/link';
import { Transition } from '@headlessui/react';
import { useClickAway } from 'react-use';
import { useUser } from '../../../context/user';

interface Props {
  onLogoutClick: () => void;
}

export const ProfileMenu: FC<Props> = ({ onLogoutClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const { user } = useUser();

  useClickAway(ref, () => {
    setIsOpen(false);
  });

  return (
    <>
      <div className="flex-shrink-0 relative ml-5">
        <div>
          <button
            onClick={() => setIsOpen(true)}
            type="button"
            className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
            id="user-menu"
          >
            <span className="sr-only">Open user menu</span>
            <img className="h-8 w-8 rounded-full" src={user.avatar} alt="" />
          </button>
        </div>
        <Transition
          show={isOpen}
          className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none"
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <div ref={ref}>
            <Link
              href="/u/[userId]"
              as={`/u/${user.username}`}
              passHref
              className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
            >
              Your Profile
            </Link>

            <a
              href="#"
              className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
              onClick={() => {
                onLogoutClick();
              }}
            >
              Sign out
            </a>
          </div>
        </Transition>
      </div>
    </>
  );
};
