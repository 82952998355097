import React, { FC, useState } from 'react';
import Link from 'next/link';
import { useApolloClient } from '@apollo/react-hooks';
import { Button } from '@pixby/ui';
import { useUser } from '../../../context/user';
import { MobileMenuButton } from './mobile-menu-button';
import { ProfileMenu } from './profile-menu';
import { MobileMenu } from './mobile-menu';
import { NavigationLink } from '../Layout';

interface Props {
  links: NavigationLink[];
  showSearch?: boolean;
}

export const Header: FC<Props> = ({ links, showSearch = false }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user } = useUser();
  const apolloClient = useApolloClient();

  // when user logs out, remove token, reset apollo cache (store) and redirect the user to login page
  const handleLogoutClick = () => {
    localStorage.removeItem('token');
    apolloClient.resetStore();
    window.location.href = '/login';
  };
  return (
    <header className="bg-white shadow-sm lg:static lg:overflow-y-visible">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div
          className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8"
          style={{ minHeight: '70px' }}
        >
          <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
            <div className="relative flex-shrink-0 flex items-center">
              <div className="flex flex-row text-primary-600 font-semibold text-lg">
                <Link href="/">Stockpicks</Link>
                <div
                  className="ml-1 bg-primary-200 rounded-full bg-gradient-to-t from-primary-600 to-pink-400"
                  style={{
                    width: '3px',
                    height: '5px',
                  }}
                ></div>
                <div
                  className="ml-0.5 bg-primary-200 rounded-full bg-gradient-to-t from-primary-600 to-pink-400"
                  style={{
                    width: '4px',
                    height: '10px',
                  }}
                ></div>
                <div
                  className="ml-0.5 bg-primary-200 rounded-full bg-gradient-to-t from-primary-600 to-pink-400"
                  style={{
                    width: '5px',
                    height: '15px',
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
            {showSearch && (
              <div className="flex items-center px-6 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0 py-4">
                <div className="w-full">
                  <label className="sr-only">Search</label>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      id="search"
                      name="search"
                      className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                      placeholder="Search"
                      type="search"
                    ></input>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden">
            <MobileMenuButton
              isOpen={isMobileMenuOpen}
              onClick={() => {
                setIsMobileMenuOpen(!isMobileMenuOpen);
              }}
            />
          </div>
          <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
            {user ? (
              <ProfileMenu onLogoutClick={handleLogoutClick} />
            ) : (
              <>
                <Link
                  href="/"
                  passHref
                  className="text-sm font-medium text-gray-900 hover:underline"
                >
                  Create account
                </Link>
                <div className="ml-6">
                  <Link href="/login" legacyBehavior>
                    <Button colorScheme="primary" size="md">
                      Login
                    </Button>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <MobileMenu
        isOpen={isMobileMenuOpen}
        links={links}
        onLogoutClick={handleLogoutClick}
      />
    </header>
  );
};
