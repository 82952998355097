import React, { FC } from 'react';
import clsx from 'clsx';

interface Props {
  variant: 'h1' | 'h2' | 'h3' | 'body1' | 'small' | 'label' | 'xs';
  as?: 'h1' | 'h2' | 'h3' | 'p' | 'li';
  className?: string;
}

const VARIANT_TAG_MAPPING = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  body1: 'p',
  label: 'span',
  small: 'span',
  xs: 'span',
};

export const Typography: FC<Props> = ({ variant, as, children, className }) => {
  const Tag =
    as || (VARIANT_TAG_MAPPING[variant] as keyof JSX.IntrinsicElements);
  switch (variant) {
    case 'h1':
      return (
        <Tag
          className={clsx([
            'text-3xl font-semibold text-gray-900 sm:truncate',
            className,
          ])}
        >
          {children}
        </Tag>
      );
    case 'h2':
      return (
        <Tag
          className={clsx([
            'text-2xl font-semibold text-gray-900 sm:truncate',
            className,
          ])}
        >
          {children}
        </Tag>
      );
    case 'h3':
      return (
        <Tag
          className={clsx(['text-xl font-semibold text-gray-900', className])}
        >
          {children}
        </Tag>
      );
    case 'body1':
      return <Tag className={clsx(['text-base', className])}>{children}</Tag>;
    case 'small':
      return <Tag className={clsx(['text-sm', className])}>{children}</Tag>;
    case 'xs':
      return <Tag className={clsx(['text-xs', className])}>{children}</Tag>;
    case 'label':
      return (
        <Tag className={clsx(['text-sm font-medium text-gray-500', className])}>
          {children}
        </Tag>
      );
    default:
      return null;
  }
};
